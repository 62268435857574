import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  textTitle: {
    fontSize: 12,
    marginLeft: 80,
    marginTop: 0,
    marginBottom: 8,
    marginRight: 0,
    width: 220,
    color: "#00386C",
  },

  viewBox: {
    borderWidth: 1,
    borderColor: "#00386C",
    padding: 10,
    position: "relative",
    zIndex: 5,
    fontSize: 12,
    marginTop: 0,
    marginBottom: 20,
    marginLeft: 70,
    marginRight: 70,
  },

  column: {
    width: "48%",
    padding: 10,
    borderWidth: 1,
    borderColor: "#00386C",
  },
  // confirmText: {
  //     marginLeft: 70,
  //     marginRight: 70
  // },
  textDate: {
    fontSize: 12,
    color: "#00386C",
  },
  confirmText: {
    fontSize: 12,
    color: "#00386C",
    marginBottom: 20,
    marginLeft: 70,
    marginRight: 70,
  },

  authorizationText: {
    fontSize: 12,
    color: "#00386C",
    marginBottom: 20,
    marginTop: 20,
    marginLeft: 70,
    marginRight: 70,
  },
  boxFlexContainer: {
    marginLeft: 70,
    marginRight: 70,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  funeralHomeName: {
    fontSize: 12,
    color: "#00386C",
  },
  tickbox: {
    borderWidth: 1,
    borderColor: "#00386C",
    width: 20,
    height: 20,
  },
});

class PDFIntroSignoff extends React.Component {
  render() {
    const is_intro_new = this.props.is_intro_new;
    const is_intro_existing = this.props.is_intro_existing;
    let nameSection = null;
    let confirmationText = null;
    let authorizationText = null;

    if (is_intro_new === true) {
      confirmationText = (
        <Text style={styles.confirmText}>
          I confirm that the products, retail price points, and images in this
          proposal are correct and I agree to proceed with the next stage of
          merchandising pending review.
        </Text>
      );
    }

    authorizationText = (
      <Text style={styles.authorizationText}>
        Authorized by SB or ACC Higgins Representative:
      </Text>
    );

    if (is_intro_existing === true) {
      nameSection = (
        <>
          <Text style={styles.textTitle}>Name</Text>

          <View style={styles.viewBox}>
            <Text style={styles.funeralHomeName}>
              {this.props.mainContact.data.display_name}
            </Text>
          </View>
        </>
      );
    }

    return (
      <View>
        <Text style={styles.textTitle}>
          As the authorised representative of
        </Text>

        <View style={styles.viewBox}>
          <Text style={styles.funeralHomeName}>{this.props.funeralHome}</Text>
        </View>

        {nameSection}

        {confirmationText}

        <View style={styles.boxFlexContainer}>
          <View style={styles.column}>
            <Text style={styles.textDate}>Date:</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.textDate}>Signature:</Text>
          </View>
        </View>

        {
          authorizationText
          &&
          <>
            <br />
            {authorizationText}
          </>
        }

        {
          authorizationText
          &&
          <View style={styles.boxFlexContainer}>
            <View style={styles.column}>
              <Text style={styles.textDate}>Date:</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.textDate}>Signature:</Text>
            </View>
          </View>

        }

      </View>
    );
  }
}

export default PDFIntroSignoff;
